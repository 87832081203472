import * as styles from './kategorie.module.scss';
import { graphql } from "gatsby"
import Layout from "../page-components/layout/layout"
import React from "react"
import RecordCard from "../page-components/recordCard"
import { GatsbyImage } from "gatsby-plugin-image"

const KategorieTemplate = ( { data }) => {

  const node = data.allWpKategorie.nodes[0];

  const records = data.allWpRecord.nodes.filter(
    n => {
      if (!n.acf?.kategorien) {
        return false;
      }

      for (const nn of n.acf.kategorien) {

        if (nn.title.toLowerCase().indexOf(node.title.toLowerCase()) !== -1) {
          return true;
        }
      }

      return false;
    }
  ).sort(
    (a, b) => {
      try {
        const stadtA = a.acf.stadt[0].title;
        const stadtB = b.acf.stadt[0].title;

        return stadtA.localeCompare(stadtB);

      } catch(err) {
        console.log(err);
        return 1;
      }
    }
  );

  return <Layout>

    <div className={styles.teaserWrapper}>
      <GatsbyImage alt={"Teaser"}
                   className={styles.teaser}
                   image={node.acf.teaser.localFile.childImageSharp.gatsbyImageData} />


      <div className={styles.teaserGradient} />
    </div>

    <div className="bg-primary-dark text-white">
      <div className="container">
        <div className="row">
          <div className="col-12 py-4 text-center">
            <h1>{node.title}</h1>
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="row pt-4 pb-5">
        {
          records
            .filter(
              r => {
                const result = !!r.acf.teaser?.localFile?.url
                if (!result) {

                }
                return result;
              }
            )
            .map(
              r => <div key={r.id} className={"col-lg-4 mt-3 " + styles.record}>
                <RecordCard showStadt={true} record={r} />
              </div>
            )
        }

        {records.length === 0 &&
        <div className={"col-12 mt-5"}>
          <p>Dieser Ort hat leider noch keine Aufnahmen.</p>
        </div>
        }


      </div>
    </div>


  </Layout>

};

export default KategorieTemplate;

export const query = graphql`query KategorieDetail($id: String!) {
    allWpKategorie(filter: {id: {eq: $id}}) {
      nodes {
        acf {
          teaser {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        
        databaseId
        content
        id
        slug
        title
      }
    }
    
     allWpRecord {
      nodes {
        databaseId
        id
        slug
        title
        acf {
          kategorien {
            ... on WpKategorie {
              id
              title
              slug
            }
          }
          teaser {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(aspectRatio: 2)
              }
            }
          }
          stadt {
            ...on WpStadt {
              id
              title
              slug
            }
          }
      }
  }
  }
    
}`;
